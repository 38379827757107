//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-656:_4704,_5372,_7080,_9160,_5904,_5900,_5460,_3168;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-656')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-656', "_4704,_5372,_7080,_9160,_5904,_5900,_5460,_3168");
        }
      }catch (ex) {
        console.error(ex);
      }